import type { ButtonV3Props } from '@jouzen/ecom-components';
import {
  ButtonV3,
  GridItem,
  LayoutGrid,
  SwipeableDots,
  SwipeableProgressRatio,
  SwipeableProvider,
  SwipeableSlider,
  TypographyV3,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useReducedMotion, useScroll, useTransform } from 'framer-motion';
import { useTranslations } from 'next-intl';
import type { ComponentPropsWithoutRef, MouseEventHandler } from 'react';
import { useRef } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import Image from '@/app/components/Image';
import Motion from '@/app/components/Motion';

import ArtDirectionImage from '../Image/ArtDirectionImage';
import type { ArticleCardDataProps } from './ArticleCard';
import ArticleCard from './ArticleCard';

interface ButtonProps extends Omit<ButtonV3Props, 'children'> {
  readonly href?: string;
  readonly label: MessageKey;
  readonly siteName?: string;
}

export interface ArticleImageProps {
  readonly alt: MessageKey;
  readonly buttonProps: ButtonProps;
  readonly heading: MessageKey;
  readonly headingClassname?: string;
  readonly mobileSrc?: string;
  readonly src: string;
}

type DotsVariant =
  | ''
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'sandstone-200'
  | 'sandstone-300'
  | 'sandstone-400'
  | 'sandstone-500';

export interface ArticleCardsModuleProps
  extends ComponentPropsWithoutRef<'section'> {
  readonly articleCards: ArticleCardDataProps[];
  readonly articleImage: ArticleImageProps;
  readonly eyebrowText: MessageKey;
  readonly eyebrowTextColor?: 'light' | 'dark';
  readonly imageTag?: () => JSX.Element;
  readonly mobileSliderAriaLabels: string[];
  readonly onClick: MouseEventHandler<HTMLAnchorElement>;
  readonly swipeableDotsProps?: {
    readonly activeColor: DotsVariant;
    readonly color: DotsVariant;
  };
  readonly wrapperClass: string;
}

const ArticleCardsModule = ({
  articleCards,
  articleImage,
  eyebrowText,
  eyebrowTextColor = 'dark',
  imageTag: Tag,
  mobileSliderAriaLabels,
  onClick,
  swipeableDotsProps,
  wrapperClass,
  ...props
}: ArticleCardsModuleProps): JSX.Element => {
  const target = useRef(null);
  const t = useTranslations();
  const { href = '', label, siteName, ...rest } = articleImage.buttonProps;
  const shouldReduceMotion = useReducedMotion();
  const { scrollYProgress } = useScroll({
    target,
    offset: ['start end', 'end start'],
  });
  const scrollY = useTransform(scrollYProgress, [0, 1], ['-20%', '5%']);

  return (
    <section
      className={cx('py-8 lg:py-16', wrapperClass)}
      data-cy="article-cards-module"
      {...props}
    >
      <LayoutGrid>
        <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main' }}>
          <TypographyV3
            weight="bold"
            Element="h2"
            color={eyebrowTextColor}
            variant="body-large"
            className="mb-8 uppercase"
            style={{
              letterSpacing: '0.05em',
            }}
          >
            {t(eyebrowText)}
          </TypographyV3>
        </GridItem>
        <GridItem
          className="relative overflow-hidden rounded-lg"
          colEnd={{ sm: 'main', md: 15 }}
          colStart={{ sm: 'main', md: 'main' }}
          rowStart={{ sm: 2 }}
        >
          <Motion
            className="absolute inset-0"
            ref={target}
            style={{
              y: shouldReduceMotion ? 0 : scrollY,
              height: shouldReduceMotion ? '100%' : '115%',
            }}
          >
            {articleImage.mobileSrc ? (
              <ArtDirectionImage
                fill
                sizes="(max-width: 768px) 100vw, 60vw"
                className="object-cover"
                localizedAlt={articleImage.alt}
                srcSet={[
                  {
                    media: '(max-width: 768px)',
                    src: articleImage.mobileSrc,
                  },
                  {
                    media: '(min-width: 769px)',
                    src: articleImage.src,
                  },
                ]}
              />
            ) : (
              <Image
                localizedAlt={articleImage.alt}
                src={articleImage.src}
                fill
                sizes="(max-width: 768px) 100vw, 60vw"
                className="object-cover"
              />
            )}
          </Motion>
          <div className="absolute inset-x-0 bottom-0 top-1/3 bg-gradient-to-b from-transparent to-black opacity-40" />
        </GridItem>
        <GridItem
          className="relative"
          colEnd={{ sm: 'main', md: 15 }}
          colStart={{ sm: 'main', md: 'main' }}
          rowStart={{ sm: 2 }}
        >
          <article className="flex h-full flex-col justify-between p-8">
            {Tag && <Tag />}
            <div
              className={cx(
                'mt-auto pt-64 md:pt-0',
                articleImage.headingClassname,
              )}
            >
              <TypographyV3
                color="light"
                Element="h3"
                variant="unset"
                weight="normal"
                className="mb-8 text-heading-base tracking-tighter md:text-heading-lg lg:text-heading-xl"
                height="tight"
              >
                {t.rich(articleImage.heading)}
              </TypographyV3>
              <ButtonV3
                data-site-name={siteName}
                href={href}
                {...rest}
                onClick={onClick}
              >
                {t(label)}
              </ButtonV3>
            </div>
          </article>
        </GridItem>
        <GridItem
          className="pt-4 md:pt-0"
          colEnd={{ sm: 'full', md: 'main' }}
          colStart={{ sm: 'main', md: 16 }}
          rowStart={{ sm: 3, md: 2 }}
        >
          <div className="hidden flex-col gap-y-6 md:flex">
            {articleCards.map((card, index) => (
              <ArticleCard key={index} {...card} onClick={onClick} />
            ))}
          </div>
          <SwipeableProvider labels={mobileSliderAriaLabels}>
            <div className="flex flex-col-reverse md:hidden">
              <div className="my-4 mr-6 flex items-center justify-between">
                <SwipeableProgressRatio />
                <SwipeableDots {...swipeableDotsProps} />
              </div>
              <SwipeableSlider className="pr-6">
                {articleCards.map((card, index) => (
                  <ArticleCard key={index} {...card} onClick={onClick} />
                ))}
              </SwipeableSlider>
            </div>
          </SwipeableProvider>
        </GridItem>
      </LayoutGrid>
    </section>
  );
};

export default ArticleCardsModule;
